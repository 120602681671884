/*! CSS Used from: https://www.ndlschool.com/plugins/bootstrap/css/bootstrap.min.css */
.section{
    display:block;
}
 a{
    background-color:transparent;
}
 a:active,a:hover{
    outline:0;
}
 @media print{
     *,:after,:before{
        color:#000!important;
        text-shadow:none!important;
        background:0 0!important;
        -webkit-box-shadow:none!important;
        box-shadow:none!important;
    }
     a,a:visited{
        text-decoration:underline;
    }
     a[href]:after{
        content:" (" attr(href) ")";
    }
     h2{
        orphans:3;
        widows:3;
    }
     h2{
        page-break-after:avoid;
    }
}
 *{
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
}
 :after,:before{
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
}
 a{
    color:#337ab7;
    text-decoration:none;
}
 a:focus,a:hover{
    color:#23527c;
    text-decoration:underline;
}
 a:focus{
    outline:5px auto -webkit-focus-ring-color;
    outline-offset:-2px;
}
 h2{
    font-family:inherit;
    font-weight:500;
    line-height:1.1;
    color:inherit;
}
 h2{
    margin-top:20px;
    margin-bottom:10px;
}
 h2{
    font-size:30px;
}
 .container{
    padding-right:15px;
    padding-left:15px;
    margin-right:auto;
    margin-left:auto;
}
 @media (min-width:768px){
     .container{
        width:750px;
    }
}
 @media (min-width:992px){
     .container{
        width:970px;
    }
}
 @media (min-width:1200px){
     .container{
        width:1170px;
    }
}
 .btn{
    display:inline-block;
    padding:6px 12px;
    margin-bottom:0;
    font-size:14px;
    font-weight:400;
    line-height:1.42857143;
    text-align:center;
    white-space:nowrap;
    vertical-align:middle;
    -ms-touch-action:manipulation;
    touch-action:manipulation;
    cursor:pointer;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    background-image:none;
    border:1px solid transparent;
    border-radius:4px;
}
 .btn:active:focus,.btn:focus{
    outline:5px auto -webkit-focus-ring-color;
    outline-offset:-2px;
}
 .btn:focus,.btn:hover{
    color:#333;
    text-decoration:none;
}
 .btn:active{
    background-image:none;
    outline:0;
    -webkit-box-shadow:inset 0 3px 5px rgba(0,0,0,.125);
    box-shadow:inset 0 3px 5px rgba(0,0,0,.125);
}
 .btn_primary{
    color:#fff;
    background-color:#337ab7;
    border-color:#2e6da4;
}
 .btn_primary:focus{
    color:#fff;
    background-color:#286090;
    border-color:#122b40;
}
 .btn_primary:hover{
    color:#fff;
    background-color:#286090;
    border-color:#204d74;
}
 .btn_primary:active{
    color:#fff;
    background-color:#286090;
    border-color:#204d74;
}
 .btn_primary:active:focus,.btn_primary:active:hover{
    color:#fff;
    background-color:#204d74;
    border-color:#122b40;
}
 .btn_primary:active{
    background-image:none;
}
 .container:after,.container:before{
    display:table;
    content:" ";
}
 .container:after{
    clear:both;
}
/*! CSS Used from: https://www.ndlschool.com/plugins/font-awesome/css/font-awesome.min.css */
 .fa{
    display:inline-block;
    font:normal normal normal 14px/1 FontAwesome;
    font-size:inherit;
    text-rendering:auto;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
}
 .fa_phone:before{
    /* content:"\f095"; */
}
/*! CSS Used from: https://www.ndlschool.com/css/style.css */
 *{
    outline:0;
    margin:0;
    padding:0;
}
 h2{
    font-family:'Open Sans', sans-serif;
    text-transform:uppercase;
    color:#202020;
    margin-top:0;
    font-weight:700;
}
 .container{
    width:100%;
}
 @media (min-width: 480px){
     .container{
        width:450px;
    }
}
 @media (min-width: 320px){
     .container{
        width:100%;
    }
}
 @media (min-width: 768px){
     .container{
        width:750px;
    }
}
 @media (min-width: 992px){
     .container{
        width:970px;
    }
}
 @media (min-width: 1200px){
     .container{
        width:1170px;
    }
}
 .btn{
    display:inline-block;
    padding:11px 20px;
    margin-bottom:0;
    font-size:16px;
    font-weight:600;
    text-align:center;
    white-space:nowrap;
    vertical-align:middle;
    -ms-touch-action:manipulation;
    touch-action:manipulation;
    cursor:pointer;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    background-image:none;
    border:1px solid transparent;
    color:#ffffff;
    border-radius:6px;
    text-transform:uppercase;
    box-shadow:0 4px 0 rgba(0, 0, 0, 0.1);
    transition:all 0.3s ease-in-out;
}
 .btn:hover{
    border:1px solid transparent;
    box-shadow:0 4px 0 rgba(0, 0, 0, 0.3)!important;
}
 .btn:focus,.btn:active{
    border:1px solid transparent;
    outline:0;
    box-shadow:0 4px 0 rgba(0, 0, 0, 0.3);
}
 a{
    color:#008cb7;
    text-decoration:none!important;
    transition:all 0.3s ease-in-out;
}
 a:hover{
    outline:0;
}
 a:focus{
    outline:0;
}
 .btn_primary{
    background-color:#008cb7!important;
    border:2px solid #008cb7!important;
    color:#ffffff;
}
 .btn_primary > i{
    margin-right:10px;
    padding-left:7px;
}
 .promotionWrapper{
    width:100%;
    background-size:cover;
    background-position:center;
    background-attachment:fixed;
    color:#ffffff;
    padding:75px 0;
    background-image: url(../images/family-mental-health.jfif);

 

}
 .promotionWrapper .promotionInfo{
    text-align:center;
}
 .promotionWrapper .promotionInfo h2{
    font-size:36px;
    color:#ffffff;
    margin-bottom:40px;
    margin:0 0 23px 0;
    /* font-family:'Dosis', sans-serif; */
    font-family: 'League Spartan', sans-serif;
    text-transform:capitalize;
}
 @media (min-width: 768px){
     .promotionWrapper .promotionInfo h2{
        line-height:36px;
    }
}
 @media (min-width: 768px){
     .promotionWrapper .promotionInfo h2{
        line-height:33px;
    }
}
/*! CSS Used from: https://www.ndlschool.com/css/default.css */
 a{
    color:#008cb7;
}
 .btn_primary,.btn_primary:hover,.btn_primary:focus,.btn_primary:active,.btn_primary:active:focus,.btn_primary:active:hover{
    background-color:#008cb7;
}
/*! CSS Used fontfaces */
 @font-face{
    font-family:'FontAwesome';
    src:url('https://www.ndlschool.com/plugins/font-awesome/fonts/fontawesome-webfont.eot?v=4.5.0');
    src:url('https://www.ndlschool.com/plugins/font-awesome/fonts/fontawesome-webfont.eot#iefix&v=4.5.0') format('embedded-opentype'),url('https://www.ndlschool.com/plugins/font-awesome/fonts/fontawesome-webfont.woff2?v=4.5.0') format('woff2'),url('https://www.ndlschool.com/plugins/font-awesome/fonts/fontawesome-webfont.woff?v=4.5.0') format('woff'),url('https://www.ndlschool.com/plugins/font-awesome/fonts/fontawesome-webfont.ttf?v=4.5.0') format('truetype'),url('https://www.ndlschool.com/plugins/font-awesome/fonts/fontawesome-webfont.svg?v=4.5.0#fontawesomeregular') format('svg');
    font-weight:normal;
    font-style:normal;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:300;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format('woff2');
    unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:300;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format('woff2');
    unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:300;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format('woff2');
    unicode-range:U+1F00-1FFF;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:300;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format('woff2');
    unicode-range:U+0370-03FF;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:300;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format('woff2');
    unicode-range:U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:300;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format('woff2');
    unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:300;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2');
    unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:300;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:400;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format('woff2');
    unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:400;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format('woff2');
    unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:400;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format('woff2');
    unicode-range:U+1F00-1FFF;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:400;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format('woff2');
    unicode-range:U+0370-03FF;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:400;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format('woff2');
    unicode-range:U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:400;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format('woff2');
    unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:400;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2');
    unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:400;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:600;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format('woff2');
    unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:600;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format('woff2');
    unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:600;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format('woff2');
    unicode-range:U+1F00-1FFF;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:600;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format('woff2');
    unicode-range:U+0370-03FF;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:600;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format('woff2');
    unicode-range:U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:600;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format('woff2');
    unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:600;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2');
    unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:600;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:700;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format('woff2');
    unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:700;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format('woff2');
    unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:700;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format('woff2');
    unicode-range:U+1F00-1FFF;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:700;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format('woff2');
    unicode-range:U+0370-03FF;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:700;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format('woff2');
    unicode-range:U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:700;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format('woff2');
    unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:700;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2');
    unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:700;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:300;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzlnC_W6EQ.woff2) format('woff2');
    unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:300;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzlmC_W6EQ.woff2) format('woff2');
    unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:300;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzloC_U.woff2) format('woff2');
    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:400;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzlnC_W6EQ.woff2) format('woff2');
    unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:400;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzlmC_W6EQ.woff2) format('woff2');
    unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:400;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzloC_U.woff2) format('woff2');
    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:600;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzlnC_W6EQ.woff2) format('woff2');
    unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:600;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzlmC_W6EQ.woff2) format('woff2');
    unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:600;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzloC_U.woff2) format('woff2');
    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:700;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzlnC_W6EQ.woff2) format('woff2');
    unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:700;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzlmC_W6EQ.woff2) format('woff2');
    unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:700;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzloC_U.woff2) format('woff2');
    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
