/*! CSS Used from: https://www.ndlschool.com/plugins/bootstrap/css/bootstrap.min.css */
.section{
    display:block;
}
 a{
    background-color:transparent;
}
 a:active,a:hover{
    outline:0;
}
 img{
    border:0;
}
 @media print{
     *,:after,:before{
        color:#000!important;
        text-shadow:none!important;
        background:0 0!important;
        -webkit-box-shadow:none!important;
        box-shadow:none!important;
    }
     a,a:visited{
        text-decoration:underline;
    }
     a[href]:after{
        content:" (" attr(href) ")";
    }
     img{
        page-break-inside:avoid;
    }
     img{
        max-width:100%!important;
    }
     h2,h3,p{
        orphans:3;
        widows:3;
    }
     h2,h3{
        page-break-after:avoid;
    }
}
 *{
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
}
 :after,:before{
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
}
 a{
    color:#337ab7;
    text-decoration:none;
}
 a:focus,a:hover{
    color:#23527c;
    text-decoration:underline;
}
 a:focus{
    outline:5px auto -webkit-focus-ring-color;
    outline-offset:-2px;
}
 img{
    vertical-align:middle;
}
 .img_responsive,.thumbnail a>img{
    display:block;
    max-width:100%;
    height:auto;
}
 h2,h3{
    font-family:inherit;
    font-weight:500;
    line-height:1.1;
    color:inherit;
}
 h2,h3{
    margin-top:20px;
    margin-bottom:10px;
}
 h2{
    font-size:30px;
}
 h3{
    font-size:24px;
}
 p{
    margin:0 0 10px;
}
 .text_center{
    text-align:center;
}
 ul{
    margin-top:0;
    margin-bottom:10px;
}
 .list_inline{
    padding-left:0;
    margin-left:-5px;
    list-style:none;
}
 .list_inline>li{
    display:inline-block;
    padding-right:5px;
    padding-left:5px;
}
 .container{
    padding-right:15px;
    padding-left:15px;
    margin-right:auto;
    margin-left:auto;
}
 @media (min-width:768px){
     .container{
        width:750px;
    }
}
 @media (min-width:992px){
     .container{
        width:970px;
    }
}
 @media (min-width:1200px){
     .container{
        width:1170px;
    }
}
 .row{
    margin-right:-15px;
    margin-left:-15px;
}
 .col_sm_3,.col_xs_12{
    position:relative;
    min-height:1px;
    padding-right:15px;
    padding-left:15px;
}
 .col_xs_12{
    float:left;
}
 .col_xs_12{
    width:100%;
}
 @media (min-width:768px){
     .col_sm_3{
        float:left;
    }
     .col_sm_3{
        width:25%;
    }
}
 .btn{
    display:inline-block;
    padding:6px 12px;
    margin-bottom:0;
    font-size:14px;
    font-weight:400;
    line-height:1.42857143;
    text-align:center;
    white-space:nowrap;
    vertical-align:middle;
    -ms-touch-action:manipulation;
    touch-action:manipulation;
    cursor:pointer;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    background-image:none;
    border:1px solid transparent;
    border-radius:4px;
}
 .btn:active:focus,.btn:focus{
    outline:5px auto -webkit-focus-ring-color;
    outline-offset:-2px;
}
 .btn:focus,.btn:hover{
    color:#333;
    text-decoration:none;
}
 .btn:active{
    background-image:none;
    outline:0;
    -webkit-box-shadow:inset 0 3px 5px rgba(0,0,0,.125);
    box-shadow:inset 0 3px 5px rgba(0,0,0,.125);
}
 .btn_link{
    font-weight:400;
    color:#337ab7;
    border-radius:0;
}
 .btn_link,.btn_link:active{
    background-color:transparent;
    -webkit-box-shadow:none;
    box-shadow:none;
}
 .btn_link,.btn_link:active,.btn_link:focus,.btn_link:hover{
    border-color:transparent;
}
 .btn_link:focus,.btn_link:hover{
    color:#23527c;
    text-decoration:underline;
    background-color:transparent;
}
 .thumbnail{
    display:block;
    padding:4px;
    margin-bottom:20px;
    line-height:1.42857143;
    background-color:#fff;
    border:1px solid #ddd;
    border-radius:4px;
    -webkit-transition:border .2s ease-in-out;
    -o-transition:border .2s ease-in-out;
    transition:border .2s ease-in-out;
}
 .thumbnail a>img{
    margin-right:auto;
    margin-left:auto;
}
 .thumbnail .caption{
    padding:9px;
    color:#333;
    min-height: 30vh;
}
 .clearfix:after,.clearfix:before,.container:after,.container:before,.row:after,.row:before{
    display:table;
    content:" ";
}
 .clearfix:after,.container:after,.row:after{
    clear:both;
}
/*! CSS Used from: https://www.ndlschool.com/plugins/font-awesome/css/font-awesome.min.css */
 .fa{
    display:inline-block;
    font:normal normal normal 14px/1 FontAwesome;
    font-size:inherit;
    text-rendering:auto;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
}
 .fa_angle_double_right:before{
    /* content:"\f101"; */
}
/*! CSS Used from: https://www.ndlschool.com/css/style.css */
 *{
    outline:0;
    margin:0;
    padding:0;
}
 ul{
    margin:0;
    padding:0;
}
 ul li{
    list-style-type:none;
}
 p{
    font-size:14px;
    color:#666666;
    font-weight:400;
    line-height:21px;
}
 h2,h3{
    font-family:'Open Sans', sans-serif;
    text-transform:uppercase;
    color:#202020;
    margin-top:0;
    font-weight:700;
}
 .container{
    width:100%;
}
 @media (min-width: 480px){
     .container{
        width:450px;
    }
}
 @media (min-width: 320px){
     .container{
        width:100%;
    }
}
 @media (min-width: 768px){
     .container{
        width:750px;
    }
}
 @media (min-width: 992px){
     .container{
        width:970px;
    }
}
 @media (min-width: 1200px){
     .container{
        width:1170px;
    }
}
 .btn{
    display:inline-block;
    padding:11px 20px;
    margin-bottom:0;
    font-size:16px;
    font-weight:600;
    text-align:center;
    white-space:nowrap;
    vertical-align:middle;
    -ms-touch-action:manipulation;
    touch-action:manipulation;
    cursor:pointer;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    background-image:none;
    border:1px solid transparent;
    color:#ffffff;
    border-radius:6px;
    text-transform:uppercase;
    box-shadow:0 4px 0 rgba(0, 0, 0, 0.1);
    transition:all 0.3s ease-in-out;
}
 .btn:hover{
    border:1px solid transparent;
    box-shadow:0 4px 0 rgba(0, 0, 0, 0.3)!important;
}
 .btn:focus,.btn:active{
    border:1px solid transparent;
    outline:0;
    box-shadow:0 4px 0 rgba(0, 0, 0, 0.3);
}
 a{
    color:#008cb7;
    text-decoration:none!important;
    transition:all 0.3s ease-in-out;
}
 a:hover{
    outline:0;
}
 a:focus{
    outline:0;
}
 .color_1{
    color:#008cb7!important;
}
 .border_color_1{
    border-color:#008cb7!important;
}
 .bg_color_4{
    background-color:#84bed6!important;
}
 .full_width{
    padding-right:15px;
    padding-left:15px;
    margin-right:auto;
    margin-left:auto;
}
 .btn_link{
    color:#9875ab;
}
 .btn_link:hover{
    color:#7c618a;
    text-decoration:none;
}
 .btn_link:focus{
    color:#7c618a;
    text-decoration:none;
}
 @media (min-width: 320px){
     .whiteSection{
        padding:50px 0;
    }
}
 @media (min-width: 768px){
     .whiteSection{
        padding:80px 0;
    }
}
 .whiteSection{
    background-color:#ffffff;
}
 .sectionTitle h2{
    /* font-family:'Dosis', sans-serif; */
    font-family: 'League Spartan', sans-serif;
    font-size:36px;
    color:#008cb7;
    text-transform:capitalize;
    float:left;
    width:100%;
}
 .sectionTitle h2 span{
    padding:0 15px;
}
 @media (min-width: 320px){
     .sectionTitle h2 span{
        display:block;
        width:100%;
        float:left;
    }
}
 @media (min-width: 768px){
     .sectionTitle h2 span{
        display:inline-block;
        width:auto;
        float:none;
    }
}
 @media (min-width: 320px){
     .sectionTitle h2{
        margin-bottom:30px;
    }
}
 @media (min-width: 768px){
     .sectionTitle h2{
        margin-bottom:70px;
    }
}
 .thumbnail{
    padding:0;
    border:none;
    border-radius:9px;
    position:relative;
    box-shadow:0 4px 0 rgba(0, 0, 0, 0.1);
}
 .thumbnail > a img{
    border-radius:9px 9px 0 0;
    width:100%;

    height: 30vh;
    object-fit: cover;
}

 

 .thumbnail img{
    border-radius:9px 9px 0 0;
    width:100%;
}
 .block h3{
    /* font-family:'Dosis', sans-serif; */
    font-family: 'League Spartan', sans-serif;
    text-transform:capitalize;
    font-weight:700;
    text-overflow:ellipsis;
    white-space:nowrap;
    overflow:hidden;
    margin:0 0 20px 0;
}
 .block h3 a{
    text-overflow:ellipsis;
    white-space:nowrap;
    overflow:hidden;
    display:block;
}
 @media (min-width: 320px){
     .block h3{
        font-size:24px;
    }
}
 @media (min-width: 992px){
     .block h3{
        font-size:18px;
    }
}
 @media (min-width: 1200px){
     .block h3{
        font-size:24px;
    }
}


@media (min-width: 768px) {

    .row{
        justify-content: center;
        align-items: center;
        align-content: center;
        text-align: center;
        display: flex;
    }
    
}

 .block .list_inline{
    margin-bottom:0;
}
 .block .list_inline li{
    line-height:21px;
    color:#666666;
    margin-bottom:5px;
}
 .block .list_inline li i{
    margin-right:6px;
}
 .block .list_inline li a{
    color:#666666;
}
 .block .list_inline .btn_link{
    text-transform:capitalize;
    font-size:14px;
    color:#666666;
    font-weight:600;
    box-shadow:none;
}
 .block .list_inline .btn_link:hover{
    box-shadow:none!important;
}
 .block .list_inline.btn_yellow .btn_link:hover{
    color:#008cb7;
}
 .coursesSection{
    padding-bottom:80px;
}
 .thumbnailContent .caption{
    border-top:5px solid #ffffff;
    background-color:#f8f8f8;
    padding:15px;
}
 .sectionTitle h2 span.shape{
    width:18px;
    height:18px;
    border-radius:50%;
    background:#ffffff;
    display:inline-block;
    padding:0;
    position:relative;
    float:none;
}
 .sectionTitle h2 span.shape:after,.sectionTitle h2 span.shape:before{
    content:"";
    height:2px;
    background:#ffffff;
    display:inline-block;
    position:absolute;
    border-radius:2px;
}
 .sectionTitle h2 span.shape:after{
    content:"";
    width:70px;
    top:6px;
}
 .sectionTitle h2 span.shape:before{
    content:"";
    width:55px;
    top:11px;
}
 .sectionTitle h2 span.shape_left:before,.sectionTitle h2 span.shape_left:after{
    right:3px;
}
 .sectionTitle h2 span.shape_right:before,.sectionTitle h2 span.shape_right:after{
    left:3px;
}
 .sectionTitle h2 span.bg_color_4,.sectionTitle h2 span.bg_color_4:after,.sectionTitle h2 span.bg_color_4:before{
    background-color:#9fccdf!important;
}
/*! CSS Used from: https://www.ndlschool.com/css/default.css */
 .color_1{
    color:#008cb7!important;
}
 .block .list_inline.btn_yellow .btn_link:hover{
    color:#008cb7;
}
 .border_color_1{
    border-color:#008cb7!important;
}
 a,.sectionTitle h2{
    color:#008cb7;
}
 .bg_color_4{
    background-color:#84bed6!important;
}
 .sectionTitle h2 span.bg_color_4,.sectionTitle h2 span.bg_color_4:after,.sectionTitle h2 span.bg_color_4:before{
    background-color:#9fccdf!important;
}
/*! CSS Used fontfaces */
 @font-face{
    font-family:'FontAwesome';
    src:url('https://www.ndlschool.com/plugins/font-awesome/fonts/fontawesome-webfont.eot?v=4.5.0');
    src:url('https://www.ndlschool.com/plugins/font-awesome/fonts/fontawesome-webfont.eot#iefix&v=4.5.0') format('embedded-opentype'),url('https://www.ndlschool.com/plugins/font-awesome/fonts/fontawesome-webfont.woff2?v=4.5.0') format('woff2'),url('https://www.ndlschool.com/plugins/font-awesome/fonts/fontawesome-webfont.woff?v=4.5.0') format('woff'),url('https://www.ndlschool.com/plugins/font-awesome/fonts/fontawesome-webfont.ttf?v=4.5.0') format('truetype'),url('https://www.ndlschool.com/plugins/font-awesome/fonts/fontawesome-webfont.svg?v=4.5.0#fontawesomeregular') format('svg');
    font-weight:normal;
    font-style:normal;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:300;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format('woff2');
    unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:300;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format('woff2');
    unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:300;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format('woff2');
    unicode-range:U+1F00-1FFF;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:300;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format('woff2');
    unicode-range:U+0370-03FF;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:300;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format('woff2');
    unicode-range:U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:300;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format('woff2');
    unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:300;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2');
    unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:300;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:400;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format('woff2');
    unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:400;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format('woff2');
    unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:400;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format('woff2');
    unicode-range:U+1F00-1FFF;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:400;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format('woff2');
    unicode-range:U+0370-03FF;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:400;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format('woff2');
    unicode-range:U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:400;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format('woff2');
    unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:400;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2');
    unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:400;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:600;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format('woff2');
    unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:600;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format('woff2');
    unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:600;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format('woff2');
    unicode-range:U+1F00-1FFF;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:600;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format('woff2');
    unicode-range:U+0370-03FF;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:600;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format('woff2');
    unicode-range:U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:600;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format('woff2');
    unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:600;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2');
    unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:600;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:700;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format('woff2');
    unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:700;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format('woff2');
    unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:700;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format('woff2');
    unicode-range:U+1F00-1FFF;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:700;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format('woff2');
    unicode-range:U+0370-03FF;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:700;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format('woff2');
    unicode-range:U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:700;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format('woff2');
    unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:700;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2');
    unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
 @font-face{
    font-family:'Open Sans';
    font-style:normal;
    font-weight:700;
    font-stretch:100%;
    src:url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:300;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzlnC_W6EQ.woff2) format('woff2');
    unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:300;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzlmC_W6EQ.woff2) format('woff2');
    unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:300;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzloC_U.woff2) format('woff2');
    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:400;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzlnC_W6EQ.woff2) format('woff2');
    unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:400;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzlmC_W6EQ.woff2) format('woff2');
    unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:400;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzloC_U.woff2) format('woff2');
    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:600;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzlnC_W6EQ.woff2) format('woff2');
    unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:600;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzlmC_W6EQ.woff2) format('woff2');
    unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:600;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzloC_U.woff2) format('woff2');
    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:700;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzlnC_W6EQ.woff2) format('woff2');
    unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:700;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzlmC_W6EQ.woff2) format('woff2');
    unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:700;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzloC_U.woff2) format('woff2');
    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
