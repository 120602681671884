.section{
    background-color: #008cb7;
    padding-bottom: 20px;
    padding-top: 20px;
    
   
}


.promotionWrapper{
    margin-top: 0px;
    margin-bottom: 0px;
}


.header{
    text-align: center;
}

.header > h1{
    color: white;
    /* margin-bottom: 60px; */
    margin-top: 0px;
   
    font-size:xx-large;
    
}

.card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card > h3, .card > h4{

    color: white;
    margin: 5px;
    

}


.card > h3{
    font-size:x-large;
}


.card > h4{
    font-size:large;
}


.img{
    height: 150px;
    width: 150px !important;
    border-radius: 75px;
}

/* EmployeesCarousel.module.css */

/* Add these styles to the existing CSS file */
.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; /* Center the text */
  position: relative; /* Position relative for hover effect */
  overflow: hidden; /* Hide overflowed text */
}

.card > h3, .card > h4 {
  color: white;
  margin: 5px;
}

/* Style the description */
.card > .description {
  color: white;
  margin: 5px;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowed text */
  text-overflow: ellipsis; /* Add ellipsis for overflowed text */
  transition: max-height 0.3s ease; /* Smooth transition */
  max-height: 40px; /* Limit height to one line */
  line-height: 20px; /* Line height to fit text in one line */
}

/* Hover effect to show full description */
.card:hover .description {
  max-height: 100px; /* Adjust height to show full text on hover */
  white-space: normal; /* Allow text to wrap */
  overflow: visible; /* Make sure the text is visible */
}
